.dialog {
  padding: calc(2 * var(--layout-spacing-unit)) calc(4 * var(--layout-spacing-unit));
  background: var(--bgc-content-container);
  border-radius: var(--border-radius-container);
  max-width: calc(50 * var(--layout-spacing-unit));
  box-shadow: var(--box-shadow);
}
@media only screen and (max-width: 748px) {
  .dialog {
    max-width: calc(100% - 8 * var(--layout-spacing-unit));
    padding: calc(2 * var(--layout-spacing-unit)) calc(2 * var(--layout-spacing-unit));
    position: absolute;
    top: 110px;
    max-height: 70vh;
    overflow: auto;
    overflow-x: hidden;
  }
}
.dialog form {
  width: calc(var(--inputfield-width) + 3rem);
}
@media only screen and (max-width: 748px) {
  .dialog form {
    max-width: 100%;
  }
}
.dialog main {
  max-height: 26rem;
  overflow: auto;
  padding-right: var(--layout-spacing-unit);
}
.dialog main > label:first-child {
  margin-top: 0;
}
@media only screen and (max-width: 748px) {
  .dialog main {
    max-height: none;
    overflow: unset;
  }
}
.dialog--unfocusable main {
  overflow: hidden;
}
.dialog footer:not(.image-upload__footer):not(.multi-select__footer) {
  margin-top: calc(2 * var(--layout-spacing-unit));
  padding-top: calc(2 * var(--layout-spacing-unit));
  border-top: thin solid var(--bgc-tab-separator);
/* padding: var(--layout-spacing-unit-small) calc(2 * var(--layout-spacing-unit))*/
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.dialog footer:not(.image-upload__footer):not(.multi-select__footer) button:last-of-type {
  margin-left: auto;
}
.dialog__header {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 748px) {
  .dialog__header {
    position: sticky;
    top: 0;
    z-index: 9;
    background-color: var(--bgc-content-container);
  }
  .dialog__header:before {
    content: '';
    width: 100%;
    height: calc(2 * var(--layout-spacing-unit));
    top: calc(-2 * var(--layout-spacing-unit));
    position: absolute;
    background-color: var(--bgc-content-container);
  }
}
.dialog__header button {
  margin-left: auto;
}
/*# sourceMappingURL=src/components/modal/ModalDialog.css.map */